.alert-enter {
  opacity: 0;
  transform: translateY(-100%) scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9) translateY(-100%);
  transition: opacity 300ms, transform 300ms;
}

@keyframes transform {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contentAnimation {
  animation: 0.8s ease-out 0s 1 transform;
}
