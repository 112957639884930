* {
  font-family: 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.page {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.header {
  width: 100%;
  position: fixed;
  z-index: 1;
  flex: 0 1 auto;
}

.overlay {
  position: relative;
}

.overlayTop {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.overlayBottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
